/// <reference types="../definitions/index"/>
import Swal from "sweetalert2";
// this way of import should  make ts-node tests work. Probably should not be changed
import { enqueueSnackbar, SnackbarKey } from "notistack";
import * as swal from "sweetalert2";
import { defaultTo, isEmpty } from "lodash";
import { logger } from "./logger";
import React from "react";
export const toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timer: 3000,
});

type ToastFunction = (title: string, text?: string) => SnackbarKey;

export interface Toasts {
  success: ToastFunction;
  error: ToastFunction;
  warn: ToastFunction;
  info: ToastFunction;
}

/**
 * Displays a success snackbar notification with the given title and optional text.
 * If no text is provided, only the title will be displayed.
 * @param {string} title - The title of the success message.
 * @param {string} [text] - Optional additional text to display in the success message.
 * @returns {SnackbarKey} - The key of the displayed snackbar.
 */
export function success(title: string, text?: string): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), {
    variant: "success",

    autoHideDuration: 5000,
  });
}

function getSnackText(title: string, text?: string): string {
  let snackBarText = title;
  if (!isEmpty(title) && !isEmpty(text)) {
    snackBarText = title + ": " + text;
  }
  return snackBarText;
}

export function error(
  title: string,
  text?: string,
  showConfirmButton?: boolean,
): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), {
    variant: "error",
    autoHideDuration: showConfirmButton ? null : 5000,
    persist: showConfirmButton,
  });
}

export function warn(title: string, text?: string): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), { variant: "warning" });
}

export function info(title: string, text?: string): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), { variant: "info" });
}

export function initializeFlashMessages() {
  try {
    $("#toast-flash-data").map((index, element) => {
      const data = $(element).data("messages") as {
        title: string;
        text: string;
        type: string;
      }[];

      data?.forEach((message) => {
        switch (message.type) {
          case "success":
            success(message.title, message.text);
            break;
          case "error":
            error(message.title, message.text);
            break;
          case "warn":
            warn(message.title, message.text);
            break;
          case "info":
            info(message.title, message.text);
            break;
          default:
            info(message.title, message.text);
        }
      });
    });
  } catch (error) {
    logger.error(error);
  }
}

let flashShown = false;
export function resetFlashMessagesShown() {
  flashShown = false;
}

export const FlashMessageToasts: React.FunctionComponent<{}> = (props) => {
  React.useEffect(() => {
    if (!flashShown) {
      initializeFlashMessages();
      flashShown = true;
    }
  }, []);
  return null;
};
